import { ApolloLink, Observable } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { print } from 'graphql/language/printer'
import objectPath from 'object-path'
import * as uuid from 'uuid'

// eslint-disable-next-line @typescript-eslint/no-require-imports
const RecursiveIterator = require('recursive-iterator')

interface CreateNetworkInterfaceArgs {
    uri: string
    credentials: string
    headers: Record<string, string>
}

type UploadNetworkInterfaceArgs = Pick<CreateNetworkInterfaceArgs, 'credentials' | 'headers'>

export default function createNetworkInterface(params: CreateNetworkInterfaceArgs): ApolloLink {
    const { uri, ...opts } = params
    return new UploadNetworkInterface(uri, opts).link
}

export class UploadNetworkInterface {
    public link: ApolloLink

    constructor(uri: string, options: UploadNetworkInterfaceArgs) {
        const uploadLink = createUploadLink({ uri })
        this.link = new ApolloLink((operation, forward) => {
            return new Observable(observer => {
                const { variables } = operation
                let hasFile = false
                const formData = new FormData()

                for (const { node, path } of new RecursiveIterator(variables)) {
                    if (node instanceof File) {
                        hasFile = true

                        const id = uuid.v4()
                        formData.append(id, node)
                        objectPath.set(variables, path.join('.'), id)
                    }
                }

                if (hasFile) {
                    formData.append('operationName', operation.operationName)
                    formData.append('query', print(operation.query))
                    formData.append('variables', JSON.stringify(variables || {}))

                    fetch(uri, {
                        method: 'POST',
                        body: formData,
                        headers: {
                            Accept: '*/*',
                            ...options.headers,
                        },
                    })
                        .then(response => response.json())
                        .then(result => {
                            observer.next(result)
                            observer.complete()
                        })
                        .catch(err => {
                            observer.error(err)
                        })
                } else {
                    forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    })
                }
            })
        }).concat(uploadLink)
    }
}
